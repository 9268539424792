<template>
  <div>
    <ex4-headline />
    <customer-list v-if="isReady"></customer-list>
  </div>
</template>

<script>
import CustomerList from './list.vue'
import Ex4Headline from '../_helpers/headline.vue'

const CustomerView = (resolve) => {
  require.ensure(
    ['./view.vue'],
    () => {
      resolve(require('./view.vue'))
    },
    'customers'
  )
}

const CustomerForm = (resolve) => {
  require.ensure(
    ['./form.vue'],
    () => {
      resolve(require('./form.vue'))
    },
    'customers'
  )
}

export const customerRoutes = [
 /* {
    path: 'view/:id',
    name: 'customerView',
    component: CustomerView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit/:id',
    name: 'customerEdit',
    component: CustomerForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add',
    name: 'customerAdd',
    component: CustomerForm,
    meta: {
      requiresAuth: true
    }
  }
  */
]

export default {
  name: 'customer',
  components: {
    CustomerView,
    CustomerForm,
    CustomerList,
    Ex4Headline
  },
  data() {
    return {
      data: { count: 0, perpage: 50, data: [], page: 1, order: { field: 'name', direction: 'ASC' } },
      error: '',
      subtitle: '',
      isLoading: true,
      isReady: false,
      searchphrase: '',
      countryList: [],
      modal: false
    }
  },
  mounted: function () {
    document.querySelector('title').innerHTML = 'Kunden | SG Fine Wine'
    this.$store.commit('setHeadline', {
      headline: 'Kunden',
      search: true,
      submenu: [],
      icon: 'contact_mail'
    })

    this.customerList()

    this.isReady = true
    this.getCountryList()
  },

  methods: {
    getCountryList: function() {
      if (this.countryList.length > 10) {
        return true
      }

      var t = this
      t.$http({
        url: '/customers/get-country-list/',
        method: 'get'
      })
        .then(function(resp) {
          t.countryList = resp.data.data

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
        })
      return false
    },
    search: function(){
      this.customerList();
    },
    customerList: function(data = null) {
      if (this.$route.path != '/customers') {
        this.$router.replace('/customers')
      }
      this.isLoading = true
      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data
        this.isLoading = false
      } else {
        var t = this
        t.$http
          .post('/customers', { page: t.data.page, search: t.searchphrase, order: t.data.order })

          .then(function(resp) {
            t.data = resp.data.data
            t.isLoading = false
            return true
          })
          .catch(function(error) {
            //console.log(error)
            t.isLoading = false
            if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
              t.error = error.response.data.message
            } else {
              t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
            }
          })
      }
    },
    customerView: function(id) {
      var t = this

      //this.$router.replace('/customers/view/' + id)

      t.$http({
        url: '/customers/view/' + id,
        method: 'get'
      })
        .then(function(resp) {
          //console.log( resp.data.data);

          t.customer = resp.data.data
          t.$buefy.modal.open({
            parent: t,
            component: CustomerView,
            hasModalCard: false,
            width: 1250,
            props: { pcustomer: resp.data.data }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
        })
    },
    customerEdit: function(id) {
      var t = this

      //this.$router.replace('/customers/edit/' + id)

      t.$http({
        url: '/customers/view/' + id,
        method: 'get'
      })
        .then(function(resp) {
          //console.log(t.countryList)

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: CustomerForm,
            hasModalCard: false,
            width: 1250,
            props: { pcustomer: resp.data.data, countryList: t.countryList }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
        })
    },
    add: function() {
      this.customerAdd()
    },
    customerAdd: function() {
      var t = this
      var pcustomer = {
        company: '',
        firstname: '',
        lastname: '',
        adress1: '',
        adress2: '',
        zip: '',
        city: '',
        country_id: 1,
        consignment_fee: 10,
        newsletter: 1,
        telephone: '',
        mobile: '',
        email: '',
        vat_id: '',
        bank_name: '',
        bank_account: '',
        bank_bic: '',
        language : 'de',
        active : 1,
      direct_debt: 0,
      newsletter: 0,
      shipping_address: 0,
      shipping_name: '',
      shipping_address1: '',
      shipping_address2: '',
      shipping_zip: '',
      shipping_city: '',
      shipping_country_id: 1
      }
      t.modal = t.$buefy.modal.open({
        parent: t,
        component: CustomerForm,
        hasModalCard: false,
        scroll: 'keep',
        props: { pcustomer: pcustomer, countryList: t.countryList }
      })
    },

    customerDelete: function(id, name) {
      this.$buefy.dialog.confirm({
        title: 'Bitte bestätigen!',
        message: 'Wollen Sie Datensatz #' + id + ' "' + name + '" wirklich löschen?',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.customerDelete2(id)
      })
    },
    customerDelete2: function(id) {
      //console.log('delete '+id);
      var t = this
      this.$http
        .post('/customers/delete/' + id, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order
        })
        .then(function(resp) {
          t.customerList()
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: 'is-danger',
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message: 'Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.',
              type: 'is-danger',
              duration: 5000
            })
          }
        })
    },
    async customerSave(formdata) {
      this.isLoading = true
      var t = this
      //console.log(t.data)
      t.$http
        .post('/customers/save', { customer: formdata, page: t.data.page, search: t.searchphrase, order: t.data.order })
        .then(function(resp) {
          t.isLoading = false
          t.customerList(resp.data.data)
          t.modal.close()
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
          t.isLoading = false
          return false
        })
    }
  }
}
</script>
