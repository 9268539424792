<template>
  <div id="app">
    <ex4-main-menu v-show="isLoggedIn" />

    <section class="container" id="main">
      <transition name="contenttrans" mode="out-in" appear>
        <router-view />
      </transition>
    </section>

    <ex4-footer v-show="isLoggedIn" />
  </div>
</template>

<script>
import Ex4MainMenu from "./components/_layout/MainMenu.vue"
import Ex4Footer from "./components/_layout/Footer.vue"

export default {
  name: "App",
  components: {
    "ex4-main-menu": Ex4MainMenu,
    "ex4-footer": Ex4Footer
  },

  data: function () {
    return {}
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    modal1Title: function () {
      return this.$store.getters.modal1.title
    },
    modal1Body: function () {
      return this.$store.getters.modal1.body
    },
    modal1Footer: function () {
      return this.$store.getters.modal1.footer
    }
  }
}
</script>

<style lang="scss">
/*
$info:#234b98;
$link: $info;
*/



@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "/css/icons/style.css";



/* ubuntu-condensed-regular - latin */
@font-face {
  font-family: "UbuC";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/ubuntu-condensed-v10-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Ubuntu Condensed"), local("UbuntuCondensed-Regular"),
    url("/fonts/ubuntu-condensed-v10-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/ubuntu-condensed-v10-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/ubuntu-condensed-v10-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/ubuntu-condensed-v10-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/ubuntu-condensed-v10-latin-regular.svg#UbuntuCondensed")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "OSa";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("/fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/roboto-v20-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}

body {
  font-family: OSa, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: UbuC, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#main {
  min-height: 700px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.clear {
  clear: both;
  height: 0px;
  flex: none;
}

.contenttrans-enter-active,
.contenttrans-leave-active {
  transition: opacity 0.5s;
}
.contenttrans-enter, .contenttrans-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.moduletrans-enter-active,
.moduletrans-leave-active {
  transition: opacity 0.5s;
}
.moduletrans-enter, .moduletrans-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

div.table-wrapper table tbody td.green,
div.table-wrapper table tbody tr.green td.status {
  border-left: 8px solid #0f961c;
}

div.table-wrapper table tbody td.gray,
div.table-wrapper table tbody tr.gray td.status {
  border-left: 8px solid #666666;
}

div.table-wrapper table tbody tr.gray td a.article-link {
  text-decoration: line-through;
}


div.table-wrapper table tbody td.lightgreen,
div.table-wrapper table tbody tr.lightgreen td.status {
  border-left: 8px solid #aff3b6;
}
div.table-wrapper table tbody td.red,
div.table-wrapper table tbody tr.red td.status {
  border-left: 8px solid #bb0000;
}
div.table-wrapper table tbody td.blue,
div.table-wrapper table tbody tr.blue td.status {
  border-left: 8px solid #8090ff;
}
div.table-wrapper table tbody td.grey,
div.table-wrapper table tbody tr.grey td.status {
  border-left: 8px solid #999999;
}
div.table-wrapper table tbody td.orange,
div.table-wrapper table tbody tr.orange td.status {
  border-left: 8px solid #ff9900;
}
div.table-wrapper table tbody td.yellow,
div.table-wrapper table tbody tr.yellow td.status {
  border-left: 8px solid #ffee03;
}
div.table-wrapper table .iconlink {
  width: 24px;
  text-align: center;
  padding: 0.5em 0.75em;
}

div.table-wrapper table tbody td .icon-grey,
i.icon-grey {
  color: #cccccc;
}

div.table-wrapper table.table thead th {
  font-size: 0.8rem;
  color: #999999;
  font-weight: 300;
}

div.table-wrapper table.table td span.tag {
  margin-top: 7px;
}

span.icon.red {
  color: #dd0000;
}

span.icon.grey,
span.grey {
  color: #cccccc;
}

span.icon.green,
span.green {
  color: #0f961c;
}

input:invalid,
select:invalid,
textarea:invalid {
  border-color: #dd0000;
}

.is-error {
  color: #dd0000;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

div.view-columns div.column {
  background-color: #f0f3f7;
  outline: 5px solid #ffffff;
}

form.smallform {
  background-color: #f0f3f7;
  margin-bottom: 20px;
  max-width: 600px;
  padding: 10px;
}

form.smallform div.control.is-clearfix {
  margin-bottom: 8px;
}

div.view-columns div.column label:not(.switch):not(.b-radio),
form.smallform label:not(.switch):not(.radio):not(.b-radio) {
  display: block;
  color: #999999;
  font-size: 0.8rem;
}

div.card-header h1.card-header-title {
  font-size: 28px;
  color: #234d98;
}

.smallgrey {
  font-size: 0.9rem;
  color: #666666;
  white-space: nowrap;
}

.smallgrey2 {
  font-size: 0.8rem;
  color: #666666;
  white-space: nowrap;
  line-height: 0.9rem;
  display: block;
}

.smallgrey3 {
  font-size: 0.8rem;
  color: #666666;
  white-space: nowrap;
  line-height: 0.9rem;
  display: inline;
}
.nowrap{
  white-space: nowrap;
}

.small {
  font-size: 0.9rem;
}
.very-small {
  font-size: 0.8rem;
}

h2.panel-heading {
  color: #234d98;
  font-weight: normal;
}
h2.panel-heading span.icon {
  padding-right: 20px;
}

label,
label.label {
  font-weight: normal;
  font-size: 12px;
  color: #999999;
}

.nobreak {
  white-space: nowrap;
  font-size: 0;
}

.badge-fw, .badge-hw{
  box-sizing: border-box;
  min-width: 18px;
  height: 16px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  background: #1e87f0;
  color: #fff !important;
  font-size: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-right:3px;

}



.badge-fw{
  background-color:rgb(165,115,65);
}

.columns.margin-bottom0{
  margin-bottom: 0 !important;
}
</style>
