<template>
  <div>
    <div class="columns is-multiline infoboxen">
      <!-- Nicht abgeschlossene Ankäufe -->
      <div class="column">
        <div class="box notification is-primary" v-on:click="moveTo('consignments')">
          <b-icon
            icon="add_shopping_cart"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">nicht abgeschlossene</div>
          <div class="title">Ankäufe</div>

          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Anzahl</div>
                <div class="title is-5">
                  {{ openConsignments.count }}
                </div>
              </div>
            </div>

            <div class="level-item is-5">
              <div>
                <div class="heading">Summe</div>
                <div class="title is-5">
                  {{ nf.format(openConsignments.summary) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="openConsignments.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

       <!-- Ankäufe -->
      <div class="column">
        <div class="box notification is-info"  v-on:click="moveTo('stocks')">
          <b-icon
            icon="package-variant-closed"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">verfügbarer</div>
          <div class="title">Lagerbestand</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Flaschen</div>
                <div class="title is-5">{{ stockStatus.freeBottles }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">EK-Summe</div>
                <div class="title is-5">{{ stockStatus.freeValue }}</div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="stockStatus.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

       <!-- Shop -->
      <div class="column">
        <div class="box notification is-warning"  v-on:click="moveTo('shop')">
          <b-icon
            icon="shopping_basket"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">aktuelle</div>
          <div class="title">Bestellungen</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">letzte 4 Wochen</div>
                <div class="title is-5">{{ shopStatus.last4weeks }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">unbearbeitet</div>
                <div class="title is-5">{{ shopStatus.unprocessed }}</div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="stockStatus.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      <!-- Offene Rechnungen -->
      <div class="column">
        <div class="box notification is-danger" v-on:click="moveTo('invoices')">
          <b-icon
            icon="document-check"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">offene</div>
          <div class="title">Rechnungen</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Anzahl</div>
                <div class="title is-5">{{ openInvoices.count }}</div>
              </div>
            </div>

            <div class="level-item">
              <div>
                <div class="heading">Summe</div>
                <div class="title is-5">
                  {{ nf.format(openInvoices.summary) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="openInvoices.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      

    </div>

    <div
      class="columns  charts"
      
    >
      <div class="column is-one-third">
        <div class="notification" id="chart3">
          <b-icon icon="add_shopping_cart" size="is-large" class="icon-left"></b-icon>
          <div class="heading">pro Monat eingebuchte</div>
          <div class="title">Ankäufe</div>
          <barchart
            :chart-data="consignmentStats"
            :options="chartOptions"
            :updated="consignmentStats.updated"
            id="revenues-chart"
          ></barchart>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="notification" id="chart3">
          <b-icon icon="shopping_basket" size="is-large" class="icon-left"></b-icon>
          <div class="heading">pro Monat erhaltende</div>
          <div class="title">Online-Bestellungen</div>
          <barchart
            :chart-data="shopStats"
            :options="chartOptions"
            :updated="shopStats.updated"
            id="fees-chart"
          ></barchart>
        </div>
      </div>

      <div class="column is-one-third">
        <div class="notification" id="chart3">
          <b-icon icon="moneybag" size="is-large" class="icon-left"></b-icon>
          <div class="heading">pro Monat abgerechnete</div>
          <div class="title">Umsätze</div>
          <barchart
            :chart-data="invoiceStats"
            :options="chartOptions"
            :updated="invoiceStats.updated"
            id="fees-chart"
          ></barchart>
        </div>
      </div>

      
     
    </div>
   
    <div class="columns charts is-multiline">
      <div class="column is-half">
        <div class="notification">
          <b-icon
            icon="add_shopping_cart"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">nicht abgeschlossene</div>
          <div class="title">Ankäufe</div>
          <b-table
            :data="openConsignmentsList.data"
            :loading="openConsignmentsList.isLoading"
            :striped="true"
            :hoverable="true"
            :mobile-cards="false"
          >
            <b-table-column
              v-slot="props"
              label="ID"
              cell-class="status"
              numeric
              sortable
            >
             <router-link :to="'/consignments/view/'+props.row.id">{{ $utilities.padLeftWithNull(props.row.id) }}</router-link>
            </b-table-column>

            <b-table-column v-slot="props" label="Kundennummer, Name" sortable>
              {{ $utilities.padLeftWithNull(props.row.customer_id) }}
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="letzte Aktion"
              sortable
              numeric
            >
              {{ props.row.fsequence_created_at }}
            </b-table-column>

            <b-table-column v-slot="props" label="Info">
              {{ props.row.info }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="column is-half">
        <div class="notification">
          <b-icon
            icon="document-check"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">offene</div>
          <div class="title">Rechnungen</div>
          <b-table
            :data="openInvoicesList.data"
            :loading="openInvoicesList.isLoading"
            :striped="true"
            :hoverable="true"
            :mobile-cards="false"
            :row-class="(row, index) => row.customer_id"
          >
            <b-table-column
              v-slot="props"
              label="ReNr."
              cell-class="status"
              numeric
              sortable
            >
             <router-link :to="'/invoices/view/'+props.row.id">{{ $utilities.padLeftWithNull(props.row.doc_id) }}</router-link>
            </b-table-column>

            <b-table-column v-slot="props" label="Kundennummer, Name" sortable>
              {{ $utilities.padLeftWithNull(props.row.customer_id) }}
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" label="Datum" sortable numeric>
              {{ props.row.invoices }}
            </b-table-column>

            <b-table-column v-slot="props" label="Betrag" sortable numeric>
              {{ props.row.total }}€
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Linechart from "../_helpers/linechart.vue"
import Barchart from "../_helpers/barchart.vue"
import debounce from "lodash/debounce"

export default {
  name: "Dashboard",
  components: {
    //Linechart,
    Barchart
  },

  data() {
    return {
      nf: Intl.NumberFormat(),
      daysForCharts: 30,
      openInvoices: {
        isLoading: true,
        count: 0,
        summary: 0
      },
      openConsignments: {
        isLoading: true,
        count: 0,
        summary: 0
      },

      stockStatus: {
        isLoading: true,
        
        freeBottles: 0,
        freeValue: 0
      },

      shopStatus: {
        isLoading: true,
        
        last4weeks: 0,
        unprocessed: 0
      },

      openConsignmentsList: { data: [], isLoading: true },
      openInvoicesList: { data: [], isLoading: true },
      data: [],
      data2: [],

      consignmentStats: {
        updated: 0,
        loading: false,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(0,209,178, 1)",
            data: [],
            fill: true,
            lineTension: 0
          }
        ]
      },
      invoiceStats: {
        updated: 0,
        loading: false,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(0,209,178, 1)",
            data: [],
            fill: true,
            lineTension: 0
          }
        ]
      },
       shopStats: {
        updated: 0,
        loading: false,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(0,209,178, 1)",
            data: [],
            fill: true,
            lineTension: 0
          }
        ]
      },
      chartOptions: {
        id: "hurz1",
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel
            }
          }
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    }
  },

  

  created: function () {
    document.querySelector('title').innerHTML = 'Dashboard | SG Fine Wine'
    this.loadData()
  },

  methods: {
    loadData: function () {
      var t = this

      t.getOpenInvoices()
      t.getOpenConsignments()
      t.getStockStatus()
      t.getOpenConsignmentsList()
      t.getOpenInvoicesList()
      t.getDataForCharts();
    },
    moveTo (target) {
      this.$router.push('/' + target);
    },
    createData(no, max) {
      if (no == 1) {
        return 0
      }
      const base = Math.floor(max / 2)
      var result = []
      for (var i = 0; i < no; i++) {
        result.push(0)
      }
      return result
    },
    getDaysFromToday(noOfDays) {
      var days = []
      var endDate = new Date()
      var currentDate = new Date()
      var mm = 0
      var dd = 0
      currentDate.setDate(currentDate.getDate() - noOfDays + 1)
      //console.log(endDate);
      //console.log(currentDate);
      while (currentDate <= endDate) {
        mm = currentDate.getMonth() + 1
        if (mm <= 9) {
          mm = "0" + mm
        }
        dd = currentDate.getDate()
        if (dd <= 9) {
          dd = "0" + dd
        }
        days.push(dd + "." + mm + ".")
        currentDate.setDate(currentDate.getDate() + 1)
      }

      return days
    },
    getOpenConsignments: async function () {
      var t = this
      t.openConsignments.isLoading = true
      t.$http
        .get("/dashboard/getopenconsignments")
        .then(function (resp) {
          t.openConsignments = resp.data.data
          t.openConsignments.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.openConsignments.isLoading = false
        })
    },
    getOpenInvoices: debounce(async function () {
      var t = this
      t.openInvoices.isLoading = true
      t.$http
        .get("/dashboard/getopeninvoices")
        .then(function (resp) {
          t.openInvoices = resp.data.data
          t.openInvoices.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.openInvoices.isLoading = false
        })
    }, 100),

    getStockStatus: debounce(async function () {
      var t = this
      t.stockStatus.isLoading = true
      t.$http
        .get("/dashboard/getstockstatus")
        .then(function (resp) {
          t.stockStatus = resp.data.data
          t.stockStatus.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.stockStatus.isLoading = false
        })
    }, 200),

     getDataForCharts: debounce(async function () {
      var t = this

       t.consignmentStats.isLoading = true
      t.invoiceStats.isLoading = true
      t.$http
        .post("/dashboard/getdataforcharts")
        .then(function (resp) {
          t.consignmentStats.labels = resp.data.data.labels;
          t.consignmentStats.datasets[0].data = resp.data.data.consignments;
          t.consignmentStats.isLoading = false
          t.consignmentStats.updated++;

          t.invoiceStats.labels = resp.data.data.labels;
          t.invoiceStats.datasets[0].data = resp.data.data.invoices;
          t.invoiceStats.isLoading = false
          t.invoiceStats.updated++;

          t.shopStats.labels = resp.data.data.labels;
          t.shopStats.datasets[0].data = resp.data.data.shop;
          t.shopStats.isLoading = false
          t.shopStats.updated++;
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          
        })
    }, 300),
   

    getOpenConsignmentsList: debounce(async function () {
      var t = this

      t.openConsignmentsList.data = []
      t.openConsignmentsList.isLoading = true

      t.$http
        .post("/dashboard/get-open-consignments-list")
        .then(function (resp) {
          t.openConsignmentsList.data = resp.data.data
          t.openConsignmentsList.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.openConsignmentsList.isLoading = false
          return []
        })
    }, 400),

    getOpenInvoicesList: debounce(async function () {
      var t = this

      t.openInvoicesList.data = []
      t.openInvoicesList.isLoading = true

      t.$http
        .post("/dashboard/get-open-invoices-list")
        .then(function (resp) {
          t.openInvoicesList.data = resp.data.data
          t.openInvoicesList.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.openInvoicesList.isLoading = false
          return []
        })
    }, 500),

   
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
div.infoboxen div.column {
  text-align: right;
}
div.infoboxen div.column div.title {
  line-height: 0.95rem;
}

div.box span.icon {
  opacity: 0.5;
}

div.level {
  margin-bottom: 0.25rem !important;
}

div.level-item {
  float: right;
}

div.heading {
  margin-bottom: 0px;
}
div.notification div.heading {
  text-align: right;
  padding-bottom: 2px;
}
div.notification {
  padding: 1rem !important;
  cursor:pointer
}

div.notification div.level-item {
  justify-content: right;
}

div.charts div.card {
  background-color: #e0e3ef;;
}
div.title {
  font-size: 28px;
  text-align: right;
}
span.icon {
  float: left;
}

.curve-btc {
  .stroke {
    stroke: #209cee !important;
    stroke-width: 2;
  }

  .fill {
    fill: #209cee;
    fill-opacity: 0.4;
  }
}

div#chart1 canvas,
div#chart2 canvas {
  max-height: 300px;
}
</style>
